<template>
  <div class="CanceledSubscription">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol class="text-center">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="mr-4">Votre abonnement a bien été résilié 😿</h1>
              <div class="mt-4" style="font-size: 22px;">
                <p>
                  <strong>Nous vous confirmons que votre abonnement à Eclerk a bien été résilié ! </strong>
                </p>
                <p>
                  Tous vos collaborateurs ne pourront plus se connecter sur la plateforme après leur déconnexion. 
                  Votre compte est bien conservé et vous 
                  pourrez retrouver votre espace Eclerk à l'identique si vous souhaitez vous réabonner.
                </p>
                <CButton size="lg" block shape="pill" color="outline-primary" @click="$router.push('/pages/login')"> Me déconnecter </CButton>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

export default {
  name: 'CanceledSubscription',
}
</script>
